import {Card, Col, Form, ListGroup, Row, Button} from "react-bootstrap";
import {isBrowser, isIOS, isMobileOnly, isTablet} from "react-device-detect";
import LoadingCircle from "../../components/LoadingCircle";
import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import {useMSOGForm} from "../../services/MSOGFormContext";
import {useTranslation} from "react-i18next";
import {element} from "prop-types";
import SignatureComponent from "../inputs/SignatureComponent";
import {useForm} from "react-hook-form";
import {useDepServices} from "../../services/DepServicesContext";
import {setJobStatus} from "../../constains/deliveryFormActions";


const ConfirmForm = ({job, handler, viewJobHandler}) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const {formValues, dispatch} = useMSOGForm();
    const {t,i18n} = useTranslation();
    const {register, unregister, formState: {errors}, handleSubmit, control, setValue} = useForm();
    const {deliveriesService} = useDepServices();
    const signPadRef = useRef();

    const onJobSelected = (e) => {
        const guid = e.target.getAttribute("data-guid");
        if (e.target.checked) {
            formValues.job_guids.push(guid);
            dispatch({type: 'update_selected_jobguids', payload: formValues.job_guids});
        } else {
            dispatch({type: 'update_selected_jobguids', payload: formValues.job_guids.filter(value => value !== guid)});
        }
    }

    useEffect( () => {
        if (isSubmitting) {
            handleSubmit(onConfirm, onError)();
        }
        setIsSubmitting(false);
    }, [isSubmitting])

    const onError = (errors, e) => {
        console.log(errors, e);
        signPadRef.current.setErrors(errors);
    }

    const onConfirm = async (vals) => {
        await deliveriesService.saveDelivery(2, formValues);
        handler();
    }

    const onReturnJob = async (e) => {
        e.preventDefault();
        await deliveriesService.saveDelivery( 0, {... formValues, job_extras: [], line_items: []});
        handler();
    }

    const handleSignChange = (e) => {

        const target = e.target,
            field_value = target.value,
            guid = target.getAttribute("data-guid");

        dispatch({type: 'update_extra', payload: {guid: guid, value: field_value}});
        if (e.persist)
            e.persist();
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            color: 'black',
        }),
    }

    const prepareSubmission = async (e) => {
        signPadRef.current.save(e);
        setIsSubmitting(true);
    }

    return (
        <Form id="confirm-list-form" className={"p-0"} onSubmit={prepareSubmission}>
            <Card>
                <Card.Header className={"text-center"}><h5>{job.job_extras[0].field_name}</h5></Card.Header>
            <Row className="form-content">
                <Col bsPrefix={"col-12"}>
                    <ListGroup>
                        {/*use job.guid as part of key to have ui re-rendering after new query*/}
                        {job.guids.map((element, index) => <ListGroup.Item key={`job${element.jobguid}-${job.guid}`} action onClick={viewJobHandler} data-guid={element.jobguid}><input className="form-check-input me-1" type="checkbox" value="" data-guid={element.jobguid} onChange={onJobSelected}/> {element.doc_number}</ListGroup.Item>)}
                    </ListGroup>
                </Col>
                <Col bsPrefix={"col-12"}>
                    <div className={`sign-component pe-0 ps-0 ${isMobileOnly ? 'mobile': ''}`} id={"signature-comp"}>
                        <SignatureComponent key={job.job_extras[0].guid} fieldname={job.job_extras[0].field_name} fieldid={job.job_extras[0].guid} value={job.job_extras[0].field_value} required={1} handler={handleSignChange} register={register} unregister={unregister} setValue={setValue} ref={signPadRef} editable={true}/>
                    </div>
                </Col>
            </Row>
            </Card>
            <Row className="justify-content-between" style={{margin:"1rem 0.5rem 0rem 0.5rem"}}>
                <Col bsPrefix={"col-3"}>
                    <button type="submit" className="btn-safe" disabled={isSubmitting || formValues.job_guids.length === 0}>
                        {isSubmitting? " ": "Confirm"}
                        <LoadingCircle isSubmitting={isSubmitting} />
                    </button>
                </Col>
                <Col bsPrefix={"col-3"} className={"text-right"}>
                    <button type="submit" className="btn-unsafe" disabled={isSubmitting || formValues.job_guids.length === 0} onClick={onReturnJob}>
                        {isSubmitting? " ": "Return"}
                        <LoadingCircle isSubmitting={isSubmitting} />
                    </button>
                </Col>
            </Row>
        </Form>
    );
}

export default ConfirmForm;
