import React from "react";
import {Form} from "react-bootstrap";
import {globalToLocalFieldGrid} from "../../utilitis/helper";
import {useTranslation} from "react-i18next";

const InputField = ({type="text", fieldname = '', description = '', fieldid = "", required = 0, fieldvalue = '', fieldcss = {}, editable = true, handler = () => {}, register = () => {}, errors = () => {}})=>{

    const [labelCol, fieldCol, labelColTablet, fieldColTablet] = globalToLocalFieldGrid(fieldcss),
        {t,i18n} = useTranslation(),
        displayname = description && description !== '' ? description : fieldname;

    return (
        <Form.Group className="row">
            <div className={`col-${labelCol} col-md-${labelColTablet} text-left`}>
                <label htmlFor={fieldid} className={`w-100 col-form-label col-${labelCol} col-md-${labelColTablet} ps-3`}>{displayname}{required == 1 ? ' *' : ''}</label>
            </div>
            <div className={`col-${fieldCol} col-md-${fieldColTablet}`}>
                {(type !== 'readonly') ? <input className={`form-control${errors && errors[fieldid] ? " is-invalid" : ""}`} type={type} id={fieldid} placeholder={fieldname} data-guid={fieldid}  defaultValue={fieldvalue} {...register(fieldid, { validate: (value) =>
                        required != 1 || !! value.trim(), onChange: handler})} disabled={(editable)? "" : "disabled"}/> : <p>{fieldvalue}</p>}
                {(type !== 'readonly') ? <div className="invalid-feedback">{t("ui:generic.input.invalid_message", {name: displayname})}</div> : <></>}
            </div>
        </Form.Group>
    );

}


export default InputField;
