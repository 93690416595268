import Paginator from "../../../components/Paginator";
import React, {createRef, useEffect, useRef, useState} from "react";
import {useDeliveryForm} from "../../../services/DeliveryFormContext";
import LineItemComponent from "./LineItemComponent";
import {scrollToElement} from "../../../utilitis/helper";
import {useSelector} from "react-redux";
import {selectAppSettings} from "../../../store";
import {ListGroup} from "react-bootstrap";


export const LineItemPanel = ({editable}) => {

    const appSettings = useSelector(selectAppSettings);
    const {formValues, dispatch} = useDeliveryForm();
    const [currentPage, setCurrentPage] = useState(0);
    const lineitemRefs = useRef([]);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const pageLimit = appSettings.lineitem_pagination_itemperpage;
    const lineList = formValues.line_items.slice(currentPage * pageLimit, currentPage * pageLimit + pageLimit );

    useEffect(() => {
        for(const item of lineList ) {
            lineitemRefs.current[item.guid] = lineitemRefs.current[item.guid]  ?? createRef();
        }
    }, [currentPage]);

    useEffect(()=>{
        let activatedItemNode = undefined;
        for(const item of lineList ) {
            if (lineitemRefs.current[item.guid] && lineitemRefs.current[item.guid].current) {
                if (lineitemRefs.current[item.guid].current.isDirty() && activatedItemNode === undefined) {
                    activatedItemNode = lineitemRefs.current[item.guid].current
                    if (! lineitemRefs.current[item.guid].current.isEditable()) {
                        lineitemRefs.current[item.guid].current.inactivate()
                    }
                }
            }
        }

        if (activatedItemNode !== undefined) {
            activatedItemNode.scrollToRef();
        }

    }, [formValues.line_items]);

    const statusHandler = (e) => {
        for(const item of lineList ) {
            if (item.guid !== e.guid && lineitemRefs.current[item.guid] && lineitemRefs.current[item.guid].current) {
                lineitemRefs.current[item.guid].current.inactivate()
            }
        }
    }

    return (<>
        <div className="line-item-elements line-item-titles d-none d-tablet-flex">
            <div className="modified-indicator"></div>
            <div className="product-title">Product</div>
            <div className="product-desc">Description</div>
            <div className="product-quantity">Quantity</div>
            <div className="product-actions">Actions</div>
        </div>
        <ListGroup className="w-100">
            {lineList.map((item, index) => <LineItemComponent key={item.guid} itemGuid={item.guid} itemIndex={index} lineActions={formValues.line_item_actions} lineItemImageLimit={appSettings.max_lineitemphoto_number} ref={lineitemRefs.current[item.guid]} editablbe={editable} statusHandler={statusHandler}/>)}
        </ListGroup>
        <Paginator postPerItem={pageLimit} totalItems={formValues.line_items.length} paginate={paginate} currentItem={currentPage}/>
    </>);
}
