export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const SET_LOGIN_STATUS = 'isLogin'; // deprecated since 0.10.1
export const SET_VEHICLE_CHECK = 'isVehicleCheckRequired';
export const SET_ONLINE_STATUS = 'isOnline';
export const SET_LOADING_STATUS = 'isLoading';
export const SET_SYNCING_STATUS = 'isSyncing';
export const SET_LOC_UPLOAD_AT = 'locationUploadAt';
export const SET_BACKGROUND_SERVICE_STATUS = 'isBackgroundServiceEnable';
export const SET_IS_QUEUE_SELECTED = 'isQueueSelected';
export const SET_IS_VEHICLE_CHECKED = 'isVehicleChecked';
export const SET_IS_CAMERA_OPEN = 'isCameraOpen';
export const SET_IS_CONFIRMATION_NEEDED = 'isConfirmationNeeded';
export const SET_DISPLAY_MODE = 'displayMode';
export const SET_SESSION_CHANGED_AT = 'sessionChangedAt';
export const SET_DELIVERY_LIST_CHANGED_AT = 'deliveryListChangedAt';
export const SET_QUERY_PARAMS = 'queryParams';
export const RESET_APP_STATE = 'RESET_APP_STATE';

export const SET_IS_SW_INIT = 'isServiceWorkerInitialized';
export const SET_IS_SW_UPDATE = 'isServiceWorkerUpdated';
export const SET_MSOG_API_ERROR = 'SET_MSOG_API_ERROR';
export const SET_NEWJOB_COUNT = 'SET_NEWJOB_COUNT';
export const SET_LOCALJOBS_COUNT = 'SET_LOCALJOBS_COUNT';
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE';
export const UPDATE_PRECHECKLIST = 'UPDATE_PRECHECKLIST';
export const UPDATE_USERQUEUES = 'UPDATE_USERQUEUES';
export const RESET_DELIVERY_STATUS = 'RESET_DELIVERY_STATUS';