import {useEffect, useState} from "react";
import {isIOS, isMobileOnly} from "react-device-detect";
import UseScreenOrientation from "./UseScreenOrientation";


const UseScrollToElement =(startTargetID, endTargetID , screenSizeCondition, orientationConditions)=>{

    const [enableScroll, setEnableScroll] = useState(true);
    let scrollTimer = null;

    const updateOrientation = () => {

        const orientation = isIOS ? (window.orientation === 90 ? "landscape-primary" : "portrait-primary") : window.screen.orientation.type;

        if(screenSizeCondition && orientationConditions.includes(orientation)) {
            const sigSelector = document.querySelector(`#${startTargetID}`);
            const yOffset = 10;
            const y = sigSelector.getBoundingClientRect().top + window.pageYOffset + yOffset;


            if (sigSelector !== null && enableScroll) {

                scrollTimer = setTimeout(() => {
                    window.scrollTo({top: y, behavior: 'smooth'});
                }, 500);
                const intersectionObserver = new IntersectionObserver((entries) => {
                    let [entry] = entries;
                    if (entry.isIntersecting) {
                        //stopTimer = setTimeout(() => alert(`${entry.target.id} is visible`), 500)
                    }
                });
                const endTarget = document.querySelector(`#${endTargetID}`);
                if (endTarget) {
                    intersectionObserver.observe(document.querySelector(`#${endTargetID}`));
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('orientationchange', updateOrientation)
        return () => {
            window.removeEventListener('orientationchange', updateOrientation)
            if (scrollTimer) clearInterval(scrollTimer);
        }
    });

    return {setEnableScroll};
}

export default UseScrollToElement;