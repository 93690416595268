// DepsContext.js
import React, {createContext, useContext} from 'react';
import {useSessionService} from "./SessionService";
import {useStore} from "react-redux";
import { useBackgroundService } from './BackgroundService';

const DepServicesContext = createContext({});

export function useDepServices() {
    return useContext(DepServicesContext);
}

export function DepServicesProvider({children, ...services}) {

    const sessionService = useSessionService();
    const backgroundService = useBackgroundService();
    const store = useStore();

    for (const key in services) {
        services[key].setStore(store);
        services[key].setSessionService(sessionService);
        services[key].setBackgroundService(backgroundService);
    }

    return (
        <DepServicesContext.Provider value={services}>
            {children}
        </DepServicesContext.Provider>
    )
}
