import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faListOl,faChevronDown,faChevronRight,faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {isMobile} from "react-device-detect";
library.add(fas,faListOl,faChevronDown,faChevronRight,faChevronUp);

const RenderLineItemBtn = ({showLineItem, handler}) =>{
    return (<button className="btn-lineitems" onClick={handler}>
        <span>Line Items</span>
        <FontAwesomeIcon icon={["fas", (showLineItem === true) ? ((!isMobile) ? "chevron-up" : "chevron-down") : ((!isMobile) ? "chevron-down" : "chevron-right")]}/>
    </button>);
}

export default RenderLineItemBtn;