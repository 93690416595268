import {useDeliveryForm} from "../../services/DeliveryFormContext";
import CheckBox from "../inputs/CheckBox";
import DropDown from "../inputs/DropDown";
import HiddenInput from "../inputs/HiddenInput";
import TextArea from "../inputs/TextArea";
import React, {useCallback, useEffect, useRef, useState} from "react";
import SubmitButton from "./buttons/SubmitButton";
import {isBrowser, isIOS, isMobileOnly, isTablet} from "react-device-detect";
import RenderLineItemBtn from "./LineItems/RenderLineItemBtn";
import UseDeliveriesInputHandler from "../../components/UseDeliveriesInputHandler";
import {CameraOpenContextProvider} from "../../services/Camera/CameraOpenContext";
import {PhotoInput} from "../inputs/PhotoInput";
import {useForm} from "react-hook-form";
import {generateValidateParams, globalToLocalFieldGrid, isEmpty, scrollToElement} from "../../utilitis/helper";
import SignatureComponent from "../inputs/SignatureComponent";
import LineItemComponent from "./LineItems/LineItemComponent";
import {LineItemPanel} from "./LineItems/LineItemPanel";
import TakePicBtn from "../inputs/TakePicBtn";
import {useDispatch, useSelector} from "react-redux";
import {selectAppSettings} from "../../store";
import InputField from "../inputs/InputField";
import {Col} from "react-bootstrap";
import {element} from "prop-types";
import useGeolocation from "react-hook-geolocation";
import {useBackgroundService} from "../../services/BackgroundService";
import {useTranslation} from "react-i18next";
import {setJobStatus} from "../../constains/deliveryFormActions";


const DeliveryForm = ({showPDFForm, handleDeliverySubmit}) => {

    const {t, i18n} = useTranslation();
    const {formValues, dispatch} = useDeliveryForm();
    const settingResponse = useSelector(selectAppSettings);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLineItem, setShowLineItem] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const [allowChangesOnLineItem, setAllowChangesOnLineItem] = useState(false);
    const {handleChange} = UseDeliveriesInputHandler();

    const deliveryStatus =  formValues.job_extras.find( element => element.field_type === "status");
    const signature =  formValues.job_extras.find( element => element.field_type === "signature");
    const statusLogic = deliveryStatus ? JSON.parse(deliveryStatus.field_logic) : [];
    const [isSignRequired, setIsSignRequired] = useState(statusLogic.length > 0 ? statusLogic[0].signRequired == 1 : 0);
    const [isPhotoRequired, setIsPhotoRequired] = useState(statusLogic.length > 0 ? statusLogic[0].photoRequired == 1 : 0);
    const [currentDeliveryStat, setCurrentDeliveryStat] = useState(statusLogic.length > 0 ? statusLogic[0].action : 3);

    const {register, unregister, formState: {errors}, handleSubmit, control, setValue} = useForm();
    const photoInputRef = useRef();
    const signPadRef = useRef();
    const selectedStatusRef = useRef();
    const backgroundService = useBackgroundService();

    const REQUIRED_WITH_SIGNATURE_BIT_SHIFT = 0;
    const REQUIRED_WITH_PHOTO_BIT_SHIFT = 1;
    const REQUIRED_BIT_SHIFT = 14;
    const REQUIRED_CONDITION = isSignRequired << REQUIRED_WITH_SIGNATURE_BIT_SHIFT | isPhotoRequired << REQUIRED_WITH_PHOTO_BIT_SHIFT;

    useEffect( () => {
        const statusObj = formValues.job_extras.find(extra => extra.field_type === "status" ),
            fieldLogic = (statusObj) ? JSON.parse(statusObj.field_logic ) : [],
            candidateIDX = fieldLogic.findIndex( option => Boolean(option.allowChangesOnLineItem))
        setAllowChangesOnLineItem(Boolean(~candidateIDX));
    }, [])

    useEffect( () => {
        if (isSubmitting) {
            if (formValues.job_status === 2) {
                handleSubmit(deliverSubmit, onValidationFailed)();
            } else {
                handleDeliverySubmit(2);
                dispatch(setJobStatus(2));
            }
        }
        setIsSubmitting(false);
    }, [isSubmitting])

    useEffect( () => {
        if(formValues.line_items !== null) {
            var hasLineItemChanges = false;
            for(const line_item of formValues.line_items) {
                if (line_item.adjustment.length > 0) {
                    hasLineItemChanges = true;
                    break;
                }
            }

            //update filter only if status changed
            if ( hasLineItemChanges && statusFilter === "" || !hasLineItemChanges && statusFilter !== "" ) {

                //if any alteration at line item and also the selected status does not qualify allow alternation constraint,
                // change the status to first found that qualify the constraint
                if ( hasLineItemChanges && statusFilter === "" ) {
                    var statusObj = formValues.job_extras.find(extra => extra.field_type === "status" ),
                        fieldLogic = JSON.parse(statusObj.field_logic ),
                        selectedOption = fieldLogic.find( option => option.value === statusObj.field_value );
                    if ( selectedOption.allowChangesOnLineItem != 1 ) {
                        const replacement = fieldLogic.find( option => option.allowChangesOnLineItem == 1 );
                        if ( replacement ) {
                            selectedStatusRef.current.updateValue(replacement.value);
                        }
                    }
                }

                setStatusFilter((hasLineItemChanges) ? "allowChangesOnLineItem" : "");
            }

        }
    }, [formValues.line_items])


    const startPhotoCaptureHandler = (e) => {
        photoInputRef.current.startCaptureHandler(e);
    }

    const getInputPhotoCount = () => {
        return (photoInputRef.current) ? photoInputRef.current.getPhotoCount() : 0;
    }

    const updateLocation = () => {
        if (locationItem) {
            let coordinateVal = "";
            if (currentDeliveryStat === 3 ) {
                const location = backgroundService.getLatLng();
                if (isEmpty(location.lat) || isEmpty(location.lng)) return;
                coordinateVal = location;
            }
            dispatch({type: 'update_extra', payload: {guid: locationItem.guid, value: coordinateVal}});
        }
    }

    const onSignatureValueChange = (e) => {
        if (e.target.value.length > 0 ) {
            updateLocation();
        }
        handleChange(e);
    }

    const parseFormElements = () => {

        const photoInput = formValues.job_extras.find(element => element.field_type === 'photo'),
            signatureInput = formValues.job_extras.find(element => element.field_type === 'signature');

        let pdfFormContent = [],
            statusElement = <></>,
            photoElement = <></>,
            signatureElement = <></>,
            locationItem,
            signatureItem,
            statusItem,
            signdateItem;

        for(const index in formValues.job_extras) {
            const item = formValues.job_extras[index],
                guid = Array.isArray(item.guid) ? item.guid[0] : item.guid,
                editable = item.field_type !== "readonly" && formValues.job_status === 2,
                isRequired = (Number(item.is_required) & (0x1 << REQUIRED_BIT_SHIFT)) > 0 || //element is required all the time
                             (Number(item.is_required) & REQUIRED_CONDITION) > 0 || //element is required when photo or signature is required
                             ((Number(item.is_required) & (0x1 << REQUIRED_WITH_SIGNATURE_BIT_SHIFT)) > 0 && !isEmpty(signatureInput.field_value)) || //element is required when signature is not empty
                             ((Number(item.is_required) & (0x1 << REQUIRED_WITH_PHOTO_BIT_SHIFT)) > 0 && !isEmpty(photoInput.field_value)); // element is required when photo is not empty
            let element, tempSelectedRef;
            switch (item.field_type) {
                case "email":
                case "number":
                case "text":
                case "readonly":
                case "time":
                    element = <InputField type={item.field_type}
                                          description={item.field_display}
                                          fieldname={item.field_name}
                                          fieldid={item.guid}
                                          required={isRequired}
                                          fieldvalue={item.field_value}
                                          fieldcss={item.field_css}
                                          handler={handleChange}
                                          register={register}
                                          errors={errors}
                                          editable={editable}
                                        />;
                    break;
                case "checkbox":
                    element = <CheckBox fieldname={item.field_name}
                                        description={item.field_display}
                                        fieldid={item.guid}
                                        required={isRequired}
                                        fieldcss={item.field_css}
                                        handler={handleChange}
                                        register={register}
                                        fieldvalue={item.field_value}
                                        errors={errors}
                                        editable={editable}
                                        />;
                    break;
                case "status":
                    tempSelectedRef = selectedStatusRef;
                case "dropdown":
                    const dropdown = <DropDown fieldname={item.field_name}
                                        description={item.field_display}
                                        fieldid={item.guid}
                                        fieldvalue={item.field_value}
                                        fieldlogic={item.field_logic}
                                        required={generateValidateParams(item.field_name, isRequired)}
                                        fieldcss={item.field_css}
                                        handler={item.field_type === "status" ? handleDeliveryStatusChange: handleChange}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                        editable={editable}
                                        filter={statusFilter}
                                        ref={tempSelectedRef}
                                        />;

                    item.field_type === "status" ? statusItem = dropdown : element = dropdown;
                    break;
                case "location":
                    locationItem = item;
                    break;
                case "hidden":
                case "sequence":
                    element = <HiddenInput key={index}
                                           fieldname={item.field_name}
                                           fieldid={item.guid}
                                           fieldvalue={item.field_value}
                                           register={register}/>;
                    break;
                case "textarea":
                    element = <TextArea required={isRequired}
                                        description={item.field_display}
                                        fieldname={item.field_name}
                                        fieldid={item.guid}
                                        fieldvalue={item.field_value}
                                        fieldcss={item.field_css}
                                        handler={handleChange}
                                        register={register}
                                        errors={errors}
                                        editable={editable}
                                        />;
                    break;
                case "signature":
                    signatureItem = item;
                    break;
                case "signdate":
                    signdateItem = item;
                    break;
                case "photo":

                    photoElement = <PhotoInput key={`webcam-${item.guid}`}
                                               description={item.field_display}
                                               itemindex={index}
                                               fieldname={item.field_name}
                                               fieldid={item.guid}
                                               required={isPhotoRequired}
                                               fieldvalue={item.field_value}
                                               imageWidth={settingResponse.photo_resolution.width}
                                               imageHeight={settingResponse.photo_resolution.height}
                                               handler={handleChange}
                                               control={control}
                                               register={register}
                                               ref={photoInputRef}
                                               errors={errors}
                                               editable={editable}>
                        <TakePicBtn objImages={getInputPhotoCount} fieldid={item.guid} imageLimitStorage={settingResponse.max_deliveryphoto_number} handler={startPhotoCaptureHandler} editable={editable}/>
                    </PhotoInput>;
                    break;
            }

            if (element) {

                const col_xs = Number(item.field_css.label) + Number(item.field_css.field),
                    col_md = (item.field_css.label_tablet && item.field_css.field_tablet) ? Number(item.field_css.field_tablet) + Number(item.field_css.field_tablet) : col_xs;

                pdfFormContent.push( (item.field_type !== "hidden") ?
                    <Col xs={col_xs} md={col_md} className="fields-group"  key={item.guid}>
                        {element}
                    </Col> : element
                );
            }
        }

        if (signatureItem) {
            signatureElement = <div id="signature-comp" className={`sign-component ${isMobileOnly?'mobile' : ''}`}>
                <SignatureComponent key={signatureItem.guid} fieldname={signatureItem.field_name} fieldid={signatureItem.guid} value={signatureItem.field_value}  signdateGuid={(signdateItem) ? signdateItem.guid : null} signdateFieldname={(signdateItem) ? signdateItem.field_name : null} signdateValue={(signdateItem) ? signdateItem.field_value : null} required={isSignRequired} handler={onSignatureValueChange} register={register} unregister={unregister} ref={signPadRef} setValue={setValue} editable={formValues.job_status === 2}/>
            </div>
        }

        if (statusItem) {
            statusElement = <Col xs={12} className="fields-group">
                {statusItem}
            </Col>

        }

        return [pdfFormContent, photoElement, signatureElement, locationItem, statusElement];
    }

    const lineItemsBtnHandler = (e) => {
        e.preventDefault();
        return (showLineItem === false ? setShowLineItem(true) : setShowLineItem(false));
    }

    const lineItemsExist = ()=>{

        if(formValues.line_items !== null && formValues.line_items.length > 0) {
            return <div className={`line-items ${showLineItem ? "active" : ""} ${isMobileOnly ? "mobile" : ""}`}>
                <Col xs={12}>
                <RenderLineItemBtn showLineItem={showLineItem}
                                   handler={(e) => lineItemsBtnHandler(e)}/>
                </Col>
                <div className={showLineItem ? "lineitems-content active" : "lineitems-content"}>
                    <LineItemPanel editable={allowChangesOnLineItem}/>
                </div>
            </div>;
        } else {
            return <></>;
        }
    }

    const handleDeliveryStatusChange = (e) => {
        handleChange(e);
        const newStatus = statusLogic.find(element => element.value === e.currentTarget.value);
        if (newStatus) {
            setIsSignRequired(newStatus.signRequired == 1 ? 1 : 0 );
            setIsPhotoRequired(newStatus.photoRequired == 1 ? 1 : 0 );
            setCurrentDeliveryStat( newStatus.action);
            updateLocation();
        }
    }

    const onValidationFailed = (errors, e)=>{
        for(const key in errors) {
            scrollToElement(key);
            break;
        }

        signPadRef.current.setErrors(errors);
    }

    const [pdfFormContent, photoElement, signatureElement, locationItem, statusElement] = parseFormElements();

    function deliverSubmit (data) {
        dispatch(setJobStatus(currentDeliveryStat));
        handleDeliverySubmit(currentDeliveryStat);
        signPadRef.current.setCanvasEditable(false);
    }

    const prepareSubmission = async (e) => {
        signPadRef.current.save(e);
        setIsSubmitting(true);
    }

    const resetJobStatus  = (e) => {
        e.preventDefault();
        if (signPadRef.current) {
            signPadRef.current.signAgainHandler(e);
        }
        setIsSubmitting(true);
    }

    return (
        <Col xs={12} className={"pdf-form-holder p-0"}>
            <div className="form-content">
                <form id="delivery-form" className="pdf-form" onSubmit={prepareSubmission}>

                    <div className="formfields">
                        <div className={`pdf-form-content me-0 ms-0 row ${isMobileOnly ? "mobile": ""} ${showPDFForm ? "active": ""}`}>
                            {statusElement}
                            {pdfFormContent}
                            {photoElement}
                        </div>
                        {lineItemsExist()}
                        {signatureElement}
                        <div className={"submit-component"}>
                            {formValues.job_status === 2 ? <SubmitButton isSubmit={isSubmitting} disabled={formValues.job_guids.length === 0}/> : signature.field_value !== "" ? <button className="btn-sign-again" onClick={resetJobStatus}>{t("ui:signature_component.btn_sign_again")}</button>:  <button className="btn-sign-again" onClick={resetJobStatus}>{t("ui:signature_component.btn_reset_status")}</button> }
                        </div>
                    </div>
                </form>
            </div>
        </Col>
    );
}

export default DeliveryForm;
