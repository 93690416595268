import {MSOGAPIClient, MSOGAPIError} from "../utilitis/MSOGAPIClient";
import MSOGDB, {UNKNOWN_USER_GUID} from "./MSOGDB";
import {isEmpty, notify, timeout} from "../utilitis/helper";
import {useCallback} from "react";
import Dexie from "dexie";
import {
    setNewJobCount,
} from "../reducers/deliveryStateReducer";
import {element} from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import {
    setConfirmationStatus,
    setDeliveryListUpdateTime,
    setLoadingStatus,
    setSyncingStatus
} from "../reducers/runtimeStateReducer";
import jobs from "../views/Jobs";
const moment = require('moment');


export class DeliveriesService  {

    constructor(initStore, initClient = null) {

        this.client = initClient ?? new MSOGAPIClient(initStore);
        this.db = MSOGDB();
        this.completedJobCnt = 0;
        this.ongoingJobCnt = 0;
    }

    dbDeliveries = async () => {
        if (! this.db.isOpen()) {
            await this.db.open()
        }
        return this.db;
    }

    insertOrUpdate = async (table, items, foreignKey = undefined, foreignValue = undefined, updateIfExist = false, updateColumns = []) => {

        items = Array.isArray(items)  ? items : (items ? [items] : []);
        let newItmes = [];
        const itemsData = (await this.dbDeliveries()).table(table);

        for (let item of items) {
            var localItem = await itemsData.where("guid").equals(item.guid).first();
            if (localItem !== undefined && updateIfExist) {
                let changes = {};
                for (const column of updateColumns) {
                    changes[column] = item[column];
                    localItem[column] = item[column];
                }
                localItem['update_cnt'] = (localItem['update_cnt']) ? (localItem['update_cnt'] + 1) : 1;
                changes['update_cnt'] = localItem['update_cnt'];
                if (await itemsData.update(item.guid, changes) === 0)
                    return false;
                localItem = {...item, ...localItem};
            } else if ( localItem === undefined ){
                if (foreignKey && foreignValue) {
                    item[foreignKey] = foreignValue;
                }
                if (await itemsData.add(item) !== item.guid)
                    return false;
                localItem = item;
            }

            newItmes.push(localItem);
        }

        return newItmes;
    }


    removeDelivery = async (guid) => {
        const connection = await this.dbDeliveries(),
            foundIdx = this.localCache.findIndex(item => item.guid === guid)

        if (~foundIdx) {
            try {
                await connection.transaction('rw', connection.jobs, connection.job_extras, connection.line_items, async () => {
                    await connection.job_extras.where("jobguid").equals(guid).delete();
                    await connection.line_items.where("jobguid").equals(guid).delete();
                    await connection.jobs.where("guid").equals(guid).delete();
                }).catch(e=> {
                    throw e;
                });
                this.localCache.splice(foundIdx, 1);
            } catch (e) {
                console.log(e);
            }
        }

    }

    insertOrUpdateDeliveries = async (response, userGuid = UNKNOWN_USER_GUID, updateIfExist = false) => {

        let updatedGuid = [],
            duplicatedGuid = [];
        for (let item of response.jobs) {
            const guid = item.guid;
            const connection = await this.dbDeliveries();
            try {
                const job_obj = await connection.transaction('rw', connection.jobs, connection.job_extras, connection.line_items, async () => {
                    const new_line_items = await this.insertOrUpdate("line_items", item.line_items, "jobguid", guid),
                        new_job_extras = await this.insertOrUpdate("job_extras", item.job_extras, "jobguid", guid);

                    delete item.job_extras;
                    delete item.line_items;
                    item.status = 2;
                    item.user_guid = userGuid;

                    const new_job_obj = await this.insertOrUpdate("jobs", item);


                    if (new_job_extras === false || new_line_items === false || new_job_obj === false) {
                        return false;
                    } else {
                        new_job_obj[0].job_extras = new_job_extras;
                        new_job_obj[0].line_items = new_line_items;
                        return new_job_obj[0];
                    }
                }).catch(e=> {
                    throw e;
                });
                if (job_obj !== false) {
                    updatedGuid.push(job_obj);
                }
            } catch (e) {
                if (e.name === "ConstraintError") {
                    duplicatedGuid.push(guid);
                }
                console.log(e);
            }
        }
        return [updatedGuid, duplicatedGuid];
    }

    buildCommpletedJobsData = () => {
        return this.localCache.filter(job => job.status === 3 ).map(job => {
            return {
                guid: job.guid,
                status: job.status,
                completed_at: job.completed_at,
                line_items: job.line_items.filter(item => item.update_cnt !== undefined).map(item => {return {guid: item.guid,photos: item.photos, adjustment: item.adjustment};}),
                job_extras: job.job_extras.filter(extra => extra.update_cnt !== undefined).map(extra => {return {guid: extra.guid, field_value: extra.field_value};})
            };
        });
    }

    buildPushbackJobsData = () => {
        return this.localCache.filter(job => job.status === 0 ).map(job => job.guid
        );
    }

    refreshDeliveryGroups = () => {
        let groups = [],
            foundIDX = -1
        for(const delivery of this.localCache) {
            foundIDX = groups.findIndex( group => group.full_address === delivery.full_address &&
                group.job_extras.length === delivery.job_extras.length &&
                group.status === delivery.status &&
                group.customer_name === delivery.customer_name &&
            delivery.job_extras.reduce((t, {field_name}) => t & (group.job_extras.findIndex( job_extra => job_extra.field_name === field_name ) !== -1), true));

            if (foundIDX !== -1) {
                let group = groups[foundIDX];
                group.guids.push({jobguid:delivery.guid, doc_number: delivery.doc_number});
                for(const index in group.job_extras) {
                    group.job_extras[index].guids.push(delivery.job_extras.find(item => item.field_name === group.job_extras[index].field_name).guid);
                }
                group.line_items = group.line_items.concat(delivery.line_items);
            } else {
                groups.push({...delivery, doc_number:'', doc_type:'', guids:[{jobguid:delivery.guid, doc_number: delivery.doc_number}], job_extras: delivery.job_extras.map(element => {return {...element, guids:[element.guid]};})})
            }
        }
        this.deliveryGroups = groups;
    }


    getDeliveryGroups = async () => {
        if (this.deliveryGroups) {
            return this.deliveryGroups;
        } else {
            if (! this.localCache) {
                await this.getLocalDeliveries();
            }
            this.refreshDeliveryGroups();
            return this.deliveryGroups;
        }
    }

    getLocalDeliveries = async () => {
        if (! this.localCache) {
            const connection = await this.dbDeliveries();
            this.localCache = await connection.jobs.where('user_guid').anyOf(this.sessionService.getUserGUID() === UNKNOWN_USER_GUID ? [UNKNOWN_USER_GUID] : [UNKNOWN_USER_GUID, this.sessionService.getUserGUID()]).toArray();
            for(const index in this.localCache) {
                this.localCache[index].line_items = await connection.line_items.where('jobguid').equals(this.localCache[index].guid).toArray();
                this.localCache[index].job_extras = await connection.job_extras.where('jobguid').equals(this.localCache[index].guid).toArray();
            }

            this.dispatchJobsCount();
            return this.localCache;
        } else {
            return this.localCache;
        }
    }

    getUnconfirmedJobs = async () => {
        if (! this.localCache) {
            await this.getLocalDeliveries();
        }

        return this.localCache.filter(job => {
            if (job.status === 2) {
                const confirm_signatures = job.job_extras.filter(extra => extra.field_type === "confirm_signature" && extra.field_value === "");
                if (confirm_signatures.length > 0) {
                    return true;
                }
            }
            return false;
        }).reduce((previousValue, currentValue) => {

            const confirm_signatures = currentValue.job_extras.filter(extra => extra.field_type === "confirm_signature");
            const jobDetail = {jobguid:currentValue.guid, doc_number: currentValue.doc_number};

            for(const extra of confirm_signatures) {
                const index = previousValue.findIndex(element => element.job_extras[0].field_name === extra.field_name);
                if (index > -1) {
                    const target = previousValue[index];
                    if (extra.field_value === "") {
                        target.guids.push(jobDetail);
                        target.job_extras[0].guids.push(extra.guid);
                    }
                } else {
                    //generate virtual guid on job and extra, so ui will always re-rendering the item after new query
                    previousValue.push({...currentValue, guid: uuidv4() , guids: (extra.field_value === "") ? [jobDetail] : [] , job_extras: [{...extra, guid: uuidv4() , field_value:"", guids:(extra.field_value === "") ? [extra.guid] : []}]});
                }
            }
            return previousValue;
        } ,[]).filter(job => job.guids.length > 0);
    }

    async insetTestData(job) {
        const [successlist, duplicated] = await this.insertOrUpdateDeliveries({jobs:[job]});
    }

    setStore(initStore) {
        this.store = initStore;
    }

    setSessionService(service) {
        this.sessionService = service;
    }
    
    setBackgroundService(service) {
        this.backgroundService = service;
    }

    syncDeliveriesJob() {
        let deliveriesStoredData = [],
            isUpdateJobsCompleted = false,
            isGetNewJobsCompleted = false,
            isDriverLogsCompleted = false;

        this.store.dispatch(setSyncingStatus(true));

        const checkCompleted = async (resolve) => {
            const connection  = await this.dbDeliveries();
            if (isGetNewJobsCompleted && isUpdateJobsCompleted && isDriverLogsCompleted) {
                this.dispatchJobsCount();
                this.store.dispatch(setSyncingStatus(false));
                this.store.dispatch(setConfirmationStatus(true));
                resolve();
            }
        }

        return new Promise(async resolve => {

            await this.getLocalDeliveries();
            const connection = await this.dbDeliveries();

            const deliveriesStoredData = this.buildCommpletedJobsData(),
                pushbackJobsData = this.buildPushbackJobsData();


            this.backgroundService.forceDriverLogUpload().then(() => {
                isDriverLogsCompleted = true;
                checkCompleted(resolve)
            });

            if (deliveriesStoredData.length > 0 || pushbackJobsData.length > 0) {
                timeout(async () => {
                    const handleResponse = async response => {
                        if (response instanceof MSOGAPIError) {
                            notify('error', `updatejobs.${response.code}`);
                        } else {
                            for (const guid of response.successful_guid) {
                                await this.removeDelivery(guid);
                            }
                            let errorMSG = undefined;
                            for (const index in response.failed_guid) {
                                if (response.error_code[index] === 409 || response.error_code[index] === 404) {
                                    await this.removeDelivery(response.failed_guid[index]);
                                }
                                errorMSG = errorMSG ?? `updatejobs.${response.error_code[index]}`
                            }

                            if ( errorMSG !== undefined ) {
                                notify('error', errorMSG);
                            }
                        }
                    }

                    if (deliveriesStoredData.length > 0) {
                        this.client.updateToken(await this.sessionService.getAccessToken());
                        await handleResponse(await this.client.postMarkJobsAsCompleted(deliveriesStoredData));
                    }

                    if (pushbackJobsData.length > 0) {
                        this.client.updateToken(await this.sessionService.getAccessToken());
                        await handleResponse(await this.client.postPushJobsBack(pushbackJobsData));
                    }

                    isUpdateJobsCompleted = true;
                    checkCompleted(resolve);
                }, 1);
            } else {
                isUpdateJobsCompleted = true;
            }

            const deliveredQueue = this.sessionService.getDeliveredQueue();

            if (deliveredQueue !== false) {
                this.client.updateToken(await this.sessionService.getAccessToken());
                const getJobsResponse = await this.client.getNewJobInQueue(deliveredQueue.guid);

                if (getJobsResponse instanceof MSOGAPIError) {
                    notify('error', `getjobs.${getJobsResponse.code}`);
                } else {
                    const [successList, duplicated] = await this.insertOrUpdateDeliveries(getJobsResponse, this.sessionService.getUserGUID());
                    for(const newJob of successList) {
                        let index = this.localCache.findIndex(element => element.guid === newJob.guid);
                        if (index === -1) {
                            this.localCache.push(newJob);
                        } else {
                            this.localCache[index] = newJob;
                        }
                    }
                    let response;

                    if (successList.length > 0) {
                        this.client.updateToken(await this.sessionService.getAccessToken());
                        response = await this.client.postSetJobsToDispatched(successList.map(job => job.guid));
                    }

                    if (duplicated.length > 0) {
                        this.client.updateToken(await this.sessionService.getAccessToken());
                        response = await this.client.postSetJobsToDispatched(duplicated);
                    }

                    if (response instanceof MSOGAPIError) {
                        notify('error', `jobstodispatched.${response.code}`);
                    } else {
                        this.store.dispatch(setNewJobCount(0));
                    }

                    this.refreshDeliveryGroups();
                }
                isGetNewJobsCompleted = true;
                checkCompleted(resolve);
            }
        });
    }

    dispatchJobsCount() {
        this.completedJobCnt = this.localCache.filter(item => item.status !== 2).length;
        this.ongoingJobCnt = this.localCache.length - this.completedJobCnt;
        //this.store.dispatch(setLocalJobsCount(ongoingJobCnt,completedJobCnt));
        this.store.dispatch(setDeliveryListUpdateTime(moment().unix()));
    }

    getLocalJobCounts() {
        return [this.completedJobCnt, this.ongoingJobCnt]
    }

    async getDeliveries() {
        return await this.getLocalDeliveries();
    }

    async saveDelivery(status, formValues) {

        const connection = await this.dbDeliveries();

        for(const jobGUID of formValues.job_guids) {
            let selectedJobIdx = this.localCache.findIndex(item => item.guid === jobGUID);

            if (selectedJobIdx >= 0) {
                let selectedJob = this.localCache[selectedJobIdx];
                await connection.transaction('rw', connection.jobs, connection.job_extras, connection.line_items, async () => {
                    //update job extras
                    for (const index in selectedJob.job_extras) {
                        let extra = selectedJob.job_extras[index],
                            updatedVal = formValues.job_extras.find(item => (item.guids) ? item.guids.includes(extra.guid) : item.guid === extra.guid);

                        if (updatedVal && updatedVal.refno && extra.field_value != updatedVal.field_value) {
                            extra.field_value = updatedVal.field_value;
                            let results = await this.insertOrUpdate("job_extras", extra, "jobguid", selectedJob.guid, true, ['field_value']);
                            selectedJob.job_extras[index] = results[0];
                        }
                    }

                    for (const index in selectedJob.line_items) {
                        let lineitem = selectedJob.line_items[index],
                            updatedVal = formValues.line_items.find(item => item.guid === lineitem.guid);

                        if (updatedVal && updatedVal.refno && (lineitem.adjustment !== updatedVal.adjustment || lineitem.photos !== updatedVal.photos)) {
                            lineitem.adjustment = updatedVal.adjustment;
                            lineitem.photos = lineitem.photos ? [...updatedVal.photos] : [];
                            let results = await this.insertOrUpdate("line_items", lineitem, "jobguid", selectedJob.guid, true, ['adjustment','photos']);
                            selectedJob.line_items[index] = results[0];
                        }
                    }

                    selectedJob.status = status;
                    var updateColumns = ['status'];
                    if (selectedJob.status > 2) {
                        selectedJob.completed_at = moment().unix();
                        updateColumns.push('completed_at');
                    }
                    this.insertOrUpdate("jobs", selectedJob, undefined, undefined, true, updateColumns);
                });
            }
        }

        this.dispatchJobsCount();
        //const jobsCnt = this.localCache.reduce((t, {status}) => {(status !== 2) ? t.completed++ : t.ongoing++; return t;}, {ongoing : 0, completed : 0});
        //this.store.dispatch(setLocalJobsCount(jobsCnt.ongoing, jobsCnt.completed));
        this.refreshDeliveryGroups();
    }

    async sendMessageToDelivery(geolocation, item) {

        if (! isEmpty(geolocation.lat) && ! isEmpty(geolocation.lng)) {

            this.client.updateToken(await this.sessionService.getAccessToken());
            const response = await this.client.postSendMessage(geolocation.lat, geolocation.lng, item.guid, item.job_queue_guid,item.full_address, item.mobile, item.has_messaged);

            if (response instanceof MSOGAPIError) {
                return {result: 'error', message: `sendmessage.${response.code}`}
                //notify('error', `sendmessage.${updateJobsResponse.code}`);
            } else {
                item.has_messaged = 1;
                const foundIdx = this.localCache.findIndex(element => item.guid === element.guid),
                    new_job_obj = await this.insertOrUpdate("jobs", item, undefined, undefined, true, ['has_messaged']);
                this.localCache[foundIdx] = new_job_obj[0];
                return {result: 'success', message:  'sendmessage.success'}
            }
        }

        return {result: 'error', message: 'sendmessage.geoerror'};
    }

    clearCache() {
        this.localCache = undefined;
    }
}
