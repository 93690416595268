import {
    SET_BACKGROUND_SERVICE_STATUS, SET_DELIVERY_LIST_CHANGED_AT,
    SET_IS_CAMERA_OPEN, SET_IS_CONFIRMATION_NEEDED, SET_IS_SW_INIT, SET_IS_SW_UPDATE,
    SET_LOADING_STATUS, SET_LOC_UPLOAD_AT,
    SET_ONLINE_STATUS, SET_SESSION_CHANGED_AT,
    SET_SYNCING_STATUS,
} from "../constains/appStateActions";
import {updateState} from "../utilitis/helper";

export const InitialRuntimeState = {
    isOnline: false,
    isBackgroundServiceEnable: false,
    isCameraOpen: false,
    isLoading: 0,
    isSyncing: 0,
    locationUploadAt: 0,
    isServiceWorkerInitialized: false,
    isServiceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    sessionChangedAt: 0,
    isConfirmationNeeded: true,
}

export function runtimeStateReducer(state = {}, action) {

    switch (action.type) {
        case SET_LOADING_STATUS:
        case SET_SYNCING_STATUS:
            const newValue = action.payload === 0 ? 0 : (state[action.type] + (action.payload ? 1 : -1))
            return updateState(state, action.type,  newValue);
        case SET_BACKGROUND_SERVICE_STATUS:
        case SET_IS_CAMERA_OPEN:
        case SET_ONLINE_STATUS:
        case SET_LOC_UPLOAD_AT:
        case SET_IS_SW_INIT:
        case SET_SESSION_CHANGED_AT:
        case SET_DELIVERY_LIST_CHANGED_AT:
        case SET_IS_CONFIRMATION_NEEDED:
            return updateState(state, action.type, action.payload);
        case SET_IS_SW_UPDATE:
            let newState = updateState(state, action.type, action.payload.isServiceWorkerUpdated);
            if (newState !== state) {
                newState.serviceWorkerRegistration = action.payload.isServiceWorkerUpdated ? action.payload.serviceWorkerRegistration : null;
            }
            return newState;
    }

    return state;
}

export const setConfirmationStatus = value =>  { return {type: SET_IS_CONFIRMATION_NEEDED, payload: value}; };
export const setDeliveryListUpdateTime = value => { return {type: SET_DELIVERY_LIST_CHANGED_AT, payload: value}; };
export const setSessionUpdateTime = value => { return {type: SET_SESSION_CHANGED_AT, payload: value}; };
export const setCameraOpenStatus = value => { return {type: SET_IS_CAMERA_OPEN, payload: value}; };
export const setBackgroundServiceStatus = value => { return { type:SET_BACKGROUND_SERVICE_STATUS, payload: value}; };
export const setOnlineState = value => { return {type:SET_ONLINE_STATUS, payload: value}; };
export const setLoadingStatus = value => { return {type: SET_LOADING_STATUS, payload: value}; };
export const setSyncingStatus = value => { return {type: SET_SYNCING_STATUS, payload: value}; };
export const setLocationUploadAt = value => { return {type: SET_LOC_UPLOAD_AT, payload: value}; };
export const setIsSWInit = value => { return {type: SET_IS_SW_INIT, payload: value}; };
export const setIsSWUpdate = (value, registration) => { return {type: SET_IS_SW_UPDATE, payload: {isServiceWorkerUpdated: value, serviceWorkerRegistration: registration}}; };