import React, {useCallback, useEffect, useState} from "react";
import JobNoChild from "./JobNoChild";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, Redirect, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {UseLocalStorage} from "../../services/UseLocalStorage";
import {push} from "connected-react-router";
import {useDepServices} from "../../services/DepServicesContext";
import {useSessionService} from "../../services/SessionService";
import LoadingCircle from "../../components/LoadingCircle";
import {selectAppSettings, selectDeliveryType, selectLoadingState, selectSyncingState} from "../../store";
import {setDeliveryType} from "../../reducers/deliveryStateReducer";
import {DELIVERY_TYPE_GROUP, DELIVERY_TYPE_SINGLE} from "../../constains/types";
import {Button, Col, Container, ListGroup, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import useGeolocation from "react-hook-geolocation";
import {isEmpty, notify} from "../../utilitis/helper";
import {useBackgroundService} from "../../services/BackgroundService";
import {setLoadingStatus} from "../../reducers/runtimeStateReducer";

const DeliveriesListing = ({dbArray,noOfJobs, unconfirmedArray})=>{

    const sessionService = useSessionService();
    const deliveryType = useSelector(selectDeliveryType);
    const settingResponse = useSelector(selectAppSettings);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {deliveriesService} = useDepServices();
    const isSyncing = useSelector(selectSyncingState);
    const isLoading = useSelector(selectLoadingState);
    const backgroundService = useBackgroundService();
    const [isMessageClientConfirmVisible, setIsMessageClientConfirmVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    let history = useHistory();
    const [isTimerActive, setTimerIsActive] = useState(false);


    const openMessageClientConfirm = () => setIsMessageClientConfirmVisible(true);
    const closeMessageClientConfirmation = () => setIsMessageClientConfirmVisible(false);

    const getJobHandler = async (event, jobGUID, status) => {
        event.preventDefault();
        if (isSyncing && status !== 2) {
            notify('error', 'select_job.sync_in_process')
        } else if (! ~unconfirmedArray.findIndex(element => Boolean(~element.guids.findIndex(jobDetail => jobDetail.jobguid
            === jobGUID)))) {
            dispatch(push(`/deliveries/${jobGUID}`));
        } else {
            dispatch(push('/confirm'));
        }
    }

    const groupByAddressHandler = () => dispatch(setDeliveryType((deliveryType === DELIVERY_TYPE_SINGLE) ? DELIVERY_TYPE_GROUP : DELIVERY_TYPE_SINGLE));

    const backToVehicle = () =>{
        dispatch(push("/vehicle"));
    }


    const renderSignBtn = (item) => {
        return (<Link className={`btn-sign ${item.status == 0 ? 'disabled-link': ''}`} to={"/deliveries/" + item.guid}
                      onClick={(e) => getJobHandler(e, item.guid, item.status)}>
            <FontAwesomeIcon icon={["fas", "file-signature"]}/>
        </Link>);
    }

    const renderMapBtn = (item) => {
        return ( !item.lat && !item.long ? <></> : <a className="btn-location border-circle"
                   href={'https://www.google.com/maps/search/?api=1&query=' + item["lat"] + ',' + item["long"]}
                   target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={["fas", "location-arrow"]}/>
        </a>);
    }

    const sendMessage = async () => {
        if (selectedItem) {
            dispatch(setLoadingStatus(true));
            const result = await deliveriesService.sendMessageToDelivery(backgroundService.getLatLng(), selectedItem);
            dispatch(setLoadingStatus(false));
            notify(result.result, result.message);
            setSelectedItem(null);
            closeMessageClientConfirmation();
        }
    }

    const showMessageConfirmation = (event, item) => {
        setSelectedItem(item);
        openMessageClientConfirm();
    }

    const isSendMessageAvailable = (item, location) => {
        const messageDriver = settingResponse.notification_default_driver,
            validateTarget = messageDriver === "sms" ? item.mobile.replace(/ /g, '') : (messageDriver === "email" ? item.email : null),
            validateRegex = messageDriver === "sms" ? /^0[0-8]\d{8}$/g : (messageDriver === "email" ? /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/ig : null)
        return validateTarget !== null && validateRegex !== null && validateRegex.test(validateTarget) && !isEmpty(location.lat) && !isEmpty(location.lng)
    }

    const renderJobs = () => {

        return Array.isArray(dbArray) ? dbArray.map((item, index) => {

            const renderPhoneBtn = () => (!item.mobile ? <></> : <a className="btn-phone" href={"tel:" + item.mobile}><FontAwesomeIcon icon={["fas", "phone-alt"]}/></a>);
            const location = backgroundService.getLatLng();
            const renderExtras = (element) => element.job_extras.length > 0 ? element.job_extras.filter((extra, pos) => extra.is_display_in_title === 1
            ).map((extra, pos) => <div key={extra.guid}>{extra.field_value}</div>) : <></>;

            return (
                <ListGroup.Item key={item.guid} style={{textDecoration: (item.status == 2) ? 'auto':'line-through'}}>
                    <div className={"add-holder-" + index}>
                        <Link to={"/deliveries/" + item.guid}
                              onClick={(e) => getJobHandler(e, item.guid, item.status)}>
                            <div className="me-3">{`${index + 1}.`}</div>
                            <div className="maxw-80">
                                <FontAwesomeIcon icon={["fas", "truck"]}/>
                            </div>
                            <div className="text-left flex-grow-1 d-flex flex-column">
                                <div><strong>{item.customer_name}</strong></div>
                                <div>{item.full_address}</div>
                                {renderExtras(item)}
                            </div>
                            <div className="icon">
                                {item.status === 0 ? <></> : <FontAwesomeIcon icon={["fas", "chevron-right"]}/>}
                            </div>
                        </Link>
                    </div>
                    <div className={"add-detail-" + index}>
                        {renderPhoneBtn()}
                        {renderMapBtn(item)}
                        {isSendMessageAvailable(item, location) ?
                            <button className={"btn-message"} onClick={event => showMessageConfirmation(event, item)}>
                                <FontAwesomeIcon icon={["fas", "comment-dots"]}/>
                            </button> : <></>
                        }
                        {renderSignBtn(item)}
                    </div>
                </ListGroup.Item>
            );
        }) : <></>;
    };

    const renderSorted = () => {

        return Array.isArray(dbArray) ? dbArray.map((item, index) => {
            return (
                <ListGroup.Item key={item.guid} style={{textDecoration: (item.status == 2) ? 'auto':'line-through'}}>
                    <div className={"add-holder-" + index}>
                        <Link to={"/deliveries/" + item.guid}
                              onClick={(e) => getJobHandler(e, item.guid, item.status)}>
                            <div className="me-3">{`${index + 1}.`}</div>
                            <div className="maxw-80">
                                <FontAwesomeIcon icon={["fas", "truck"]}/>
                            </div>
                            <div className="text-left flex-grow-1 d-flex flex-column">
                                <div><strong>{item["customer_name"]}</strong></div>
                                <div>{item["full_address"]}</div>
                            </div>
                            <div className="icon">
                                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                            </div>
                        </Link>
                    </div>
                    <div className="group-addlist">
                        <ul>{item.guids.map(element =>
                            <li key={element.jobguid}>
                                <div><FontAwesomeIcon icon={["fas", "file"]}/>Doc No: {element.doc_number}</div>
                            </li>)}
                        </ul>
                    </div>
                    <div className={"add-detail-" + index}>
                        {renderMapBtn(item)}
                        {renderSignBtn(item)}
                    </div>
            </ListGroup.Item>);
        }) : <></>;

    }

    const renderAll = () => {
        return (deliveryType === DELIVERY_TYPE_GROUP ?  renderSorted() : renderJobs());
    };

    const jobList = noOfJobs === 0 ? <ListGroup.Item variant={"secondary"}> {! sessionService.isVehicleCheckDone() ? <><p>t("ui:deliveries.message_vehicle_check_failed")</p>
        <button className="btn-def-rnd btn-blue-base btn-veh-back" onClick={backToVehicle}>
        <FontAwesomeIcon  icon={["fas", "arrow-left"]} />
        <span>Back to Vehicle Check</span>
        </button></> : t("ui:deliveries.message_no_result")}</ListGroup.Item>: renderAll();

    return (
        <Container fluid>
            <div className="row data-list deliveries-content">
                <JobNoChild noOfJobs={noOfJobs} handler={groupByAddressHandler} disabled={Boolean(settingResponse.enable_group_delivery) != true}/>
                <div className="data-list-content delivery-list">
                    <ListGroup>
                        {jobList}
                    </ListGroup>
                </div>
            </div>
            <Modal show={isMessageClientConfirmVisible} onHide={closeMessageClientConfirmation} centered>
                <Modal.Body><h5>{(selectedItem && selectedItem.has_messaged) ? t("ui:deliveries.message_send_delay_msg") : t("ui:deliveries.message_send_msg")}</h5></Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" onClick={closeMessageClientConfirmation} disabled={isLoading}>
                        <FontAwesomeIcon icon={["fas", "times"]}/> {t("ui:generic.button.no")}
                    </Button>
                    <Button variant="primary" onClick={sendMessage} disabled={isLoading}>
                        <FontAwesomeIcon icon={(isLoading) ? ["fas", "fa-spinner"] : ["fas", "check"]} className={(isLoading) ? "fa-pulse" : ""}/> {t("ui:generic.button.yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
export default DeliveriesListing;
