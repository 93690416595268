import React, {useEffect, useReducer, useRef, useState} from "react";
import {Button, CloseButton, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import LoadingCircle from "../../components/LoadingCircle";
import {MSOGFormContextProvider} from "../../services/MSOGFormContext";
import formReducer from "../../reducers/deliveryFormReducer";
import {useDepServices} from "../../services/DepServicesContext";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import ConfirmForm from "./ConfirmForm";
import {setApplayerOrientationLock} from "../../utilitis/helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setConfirmationStatus} from "../../reducers/runtimeStateReducer";
import PdfViewer from "../../components/PdfViewer";

const Confirm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {deliveriesService} = useDepServices();
    const reducer = formReducer;
    const [formValues, formDispatch] = useReducer(reducer, {});
    const [unconfirmedJobs, setUnconfirmedJobs] = useState();
    const [isDirty, setIsDirty] = useState(true);
    const [showSkipConfirmation, setShowSkipConfirmation] = useState(false);
    const [jobPDF, setJobPDF] = useState(null);
    const pdfViewRef = useRef();

    const popupSkipConfirmation = () => setShowSkipConfirmation(true);
    const closeSkipConfirmation = () => setShowSkipConfirmation(false);

    useEffect( () => {

        setApplayerOrientationLock(false);

        return () => {
            setApplayerOrientationLock(true);
        }
    },[]);


    useEffect( () => {
        if (jobPDF) {
            pdfViewRef.current.fullPDFHandler();
        }
    },[jobPDF]);

    useEffect(() => {
        const fetchData = async () => {
            const dbUnconfirmedJobs = await deliveriesService.getUnconfirmedJobs();

            if (dbUnconfirmedJobs.length > 0) {
                formDispatch({type:'initial_value', payload:{job_guids:[], job_extras:[...dbUnconfirmedJobs[0].job_extras], line_items:[]}});
                setUnconfirmedJobs(dbUnconfirmedJobs);
            } else {
                dispatch(push('/deliveries'));
            }
        }

        if (isDirty) {
            fetchData();
            setIsDirty(false);
        }
    }, [isDirty]);

    const handleSubmit = () => {
        setIsDirty(true);
    }

    const handleViewJob = (event) => {
        if (event.target.nodeName === "BUTTON") {
            event.preventDefault();
            const guid = event.target.dataset.guid,
                job = unconfirmedJobs.find(element =>  Boolean(~element.guids.findIndex(jobDetail => jobDetail.jobguid
                    === guid)));
            if (job !== undefined) {
                setJobPDF(job.job_pdf);
            }
        }
    }

    const handleViewJobClose = () => {
        setJobPDF(null);
    }

    const renderForm = () => {
        return (
            <div className="data-list-content">
                <MSOGFormContextProvider formValues={formValues} dispatch={formDispatch}>
                    <ConfirmForm job={unconfirmedJobs[0]} handler={handleSubmit} viewJobHandler={handleViewJob}></ConfirmForm>
                </MSOGFormContextProvider>
            </div>
        );
    }

    const renderLoading = () => {
        return (
            <div className="data-loading"><h3>Loading... </h3><LoadingCircle isSubmitting={true} /></div>
        );
    }

    const skipConfirmation = () => {
        dispatch(setConfirmationStatus(false));
        dispatch(push('/deliveries'));
    }

    return (
        <div>
            <Container fluid>
                <div className="data-list">
                    <div className="data-list-header d-flex justify-content-center align-items-center">
                        <h3 className={"w-100"}>{t("ui:confirm.header")}</h3><CloseButton onClick={popupSkipConfirmation} className={"float-end me-2 me-md-3"}></CloseButton>
                    </div>
                    {unconfirmedJobs ? renderForm() : renderLoading()}
                </div>

            </Container>
            <Modal show={showSkipConfirmation} onHide={closeSkipConfirmation} centered>
                <Modal.Body><h5>{t("ui:confirm.message_skip_confirmation")}</h5></Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" onClick={closeSkipConfirmation}>
                        <FontAwesomeIcon icon={["fas", "times"]}/> {t("ui:generic.button.no")}
                    </Button>
                    <Button variant="primary" onClick={skipConfirmation}>
                        <FontAwesomeIcon icon={["fas", "check"]}/> {t("ui:generic.button.yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <PdfViewer jobPDF={jobPDF} jobExtras={[]} showModelOnly={true} ref={pdfViewRef} handleClose={handleViewJobClose}></PdfViewer>
        </div>
    );
}

export default Confirm;
