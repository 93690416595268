/** Imports from external libraries / files **/

import React, {createRef, useEffect, useReducer, useRef, useState} from "react";
import {useSelector, useDispatch, useStore} from "react-redux";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas,faCamera, faPlusCircle,faTimes} from '@fortawesome/free-solid-svg-icons';
import {push} from "connected-react-router";
import {Redirect} from "react-router-dom";


import LoadingCircle from "../../components/LoadingCircle";
import {notify} from "../../utilitis/helper";
import {selectPrecheckList} from "../../store";
import {useSessionService} from "../../services/SessionService";
import {createFetchPrecheckList} from "../../reducers/deliveryStateReducer";
import {MSOGFormContextProvider} from "../../services/MSOGFormContext";
import precheckFormReducer from "../../reducers/precheckFormReducer";
import PrecheckForm from "./PrecheckForm";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SET_MSOG_API_ERROR} from "../../constains/appStateActions";
import UseLogoutWhenUnAuth from "../../components/UseLogoutWhenUnAuth";
import {MSOGAPIClient} from "../../utilitis/MSOGAPIClient";

library.add(fas,faCamera,faPlusCircle, faTimes);

const Vehicle = () =>{
    /** Global localstorage variables / variables declaration / constant declaration **/


    const dispatch = useDispatch();
    const sessionService = useSessionService();
    const precheckList = useSelector(selectPrecheckList);
    const {handleUnAuth} = UseLogoutWhenUnAuth();
    const store = useStore();

    let favouriteQueue = sessionService.getDeliveredQueue();
    const reducer = precheckFormReducer;
    const [formValues, formDispatch] = useReducer(reducer, []);
    const {t} = useTranslation();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        if(! sessionService.isVehicleCheckDone() && sessionService.isLogin() && favouriteQueue !== false ) {

            if (!precheckList || precheckList.update_cnt === undefined) {
                dispatch(createFetchPrecheckList(sessionService, new MSOGAPIClient(store), function (action) {
                    if (action.type === SET_MSOG_API_ERROR ) {
                        //sessionService.setDeliveredQueue(undefined);
                        //dispatch(push('/jobs'));
                        if (action.payload.code === 401) {
                            handleUnAuth();
                        } else {
                            dispatch(push('/error', action));
                        }
                    } else if (mounted.current) {
                        formDispatch({type: 'initial_value', payload: action.payload.value});
                    }
                }));
            } else {
                formDispatch({type:'initial_value', payload: precheckList.data});
            }
        }

        return () => {
            mounted.current = false;
        }

    },[precheckList]);

    const onSaveSubmit = () => {
        dispatch(push('/deliveries'));
    }

    const onUnsaveSubmit = () => {
        dispatch(push('/jobs'));
    }

    useEffect(() => {
        if(favouriteQueue === false){
            sessionService.isLogin() && notify('error', "vehicle_check.no_fav_queue" );
            dispatch(push('/jobs'));
        } else if (sessionService.isVehicleCheckDone()) {
            dispatch(push('/deliveries'));
        }
    },[]);


    const renderVehicleForm = () =>{
        return(
            <div className="data-list-content vehicle-content">
                <MSOGFormContextProvider formValues={formValues} dispatch={formDispatch}>
                    <PrecheckForm guid={precheckList.guid} handleSafeSubmit={onSaveSubmit} handleUnsafeSubmit={onUnsaveSubmit}></PrecheckForm>
                </MSOGFormContextProvider>
            </div>
        );
    }

    return(
        <Container fluid>
            <div className="data-list">
                <div className="data-list-header"><h3>{t("ui:vehicle_checklist.header")}</h3></div>
                {precheckList.update_cnt === undefined  ? <div className="data-loading"><h3>Loading... </h3><LoadingCircle isSubmitting={precheckList.update_cnt === undefined || Object.keys(formValues).length === 0} /></div> : renderVehicleForm()}
            </div>
        </Container>
    );
};

export default Vehicle;
