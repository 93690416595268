import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'remove-focus-outline';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { ConnectedRouter } from 'connected-react-router';
import {BrowserRouter, StaticRouter} from "react-router-dom";
import {persistStore} from "redux-persist";
import {PersistGate} from 'redux-persist/lib/integration/react';

import App from './js/App';
import DevelopmentDelivery from "./js/testings/DevelopmentDelivery";
import './css/app.scss';
import * as serviceWorker from './serviceWorker';


import {configureStore, history} from "./js/store";
import {DepServicesProvider} from "./js/services/DepServicesContext";

import message_en from "./translations/en/message.json";
import ui_en from "./translations/en/ui.json";
import {OnlineStatusProvider} from "./js/utilitis/useOnlineStatus";

import {SessionService, SessionServiceProvider} from "./js/services/SessionService";
import {BackgroundService, BackgroundServiceProvider} from "./js/services/BackgroundService";
import 'bootstrap/dist/css/bootstrap.min.css';
import {DeliveriesService} from "./js/services/DeliveriesService";
import DevelopmentVehicleCheck from "./js/testings/DevelopmentVehicleCheck";
import DevelopmentConfirm from "./js/testings/DevelopmentConfirm";
import {setIsSWInit, setIsSWUpdate} from "./js/reducers/runtimeStateReducer";
import UseLogoutWhenUnAuth from "./js/components/UseLogoutWhenUnAuth";
import {MSOGAPIClient} from "./js/utilitis/MSOGAPIClient";


const store = configureStore();
const persistor = persistStore(store);
const sessionService = new SessionService(store, new MSOGAPIClient(store));
const backgroundService = BackgroundService(store);
const deliveriesService = new DeliveriesService(store);

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            message: message_en,
            ui: ui_en
        },
    },
});

ReactDOM.render(

    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <StaticRouter>
                <BrowserRouter>
                    <I18nextProvider i18n={i18next}>
                        <OnlineStatusProvider initClient={new MSOGAPIClient(store)}>
                            <SessionServiceProvider service={sessionService}>
                                <ConnectedRouter history={history}>
                                    <BackgroundServiceProvider service={backgroundService}>
                                        <DepServicesProvider deliveriesService={deliveriesService}>
                                            {/*<DevelopmentVehicleCheck />*/}
                                            {/*<DevelopmentDelivery/>*/}
                                            {/*<DevelopmentConfirm />*/}
                                            <App />
                                        </DepServicesProvider>
                                    </BackgroundServiceProvider>
                                </ConnectedRouter>
                            </SessionServiceProvider>
                        </OnlineStatusProvider>
                    </I18nextProvider>
                </BrowserRouter>
            </StaticRouter>
        </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
    onSuccess: () => store.dispatch(setIsSWInit(true)),
    onUpdate: registration => store.dispatch(setIsSWUpdate(true, registration)),
});

