/** Imports from external libraries / files **/

import React, {useEffect, useState,useCallback} from "react";
import {UseLocalStorage} from "../../services/UseLocalStorage";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    fas,
    faSuitcase,
    faMapMarkedAlt,
    faSort,
    faFileSignature,
    faArrowLeft,
    faInfoCircle,
    faMap,
    faPhoneAlt,
    faLocationArrow,
    faTruck,
    faCommentDots,
    faCamera,
    faChevronRight,
    faInfo,
    faFile,
    faMapMarked,
    faUser,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

import {Link, Redirect, useHistory, useRouteMatch} from "react-router-dom";
import {DeliveryFormContextProvider, useDeliveryForm} from "../../services/DeliveryFormContext";
import DeliveriesListing from "./DeliveriesListing";
import LoadingCircle from "../../components/LoadingCircle";
import {useDepServices} from "../../services/DepServicesContext";
import {useDispatch, useSelector} from "react-redux";
import {buildHeader, notify} from "../../utilitis/helper";
import {useOnlineStatus} from "../../utilitis/useOnlineStatus";
import {push} from "connected-react-router";
import {useSessionService} from "../../services/SessionService";
import {
    selectConfirmationState,
    selectDeliveryListUpdateTime,
    selectDeliveryType,
    selectOnlineState,
    selectVehicleCheckedState
} from "../../store";
import SingleJobDelivery from "./SingleJobDelivery";
import GroupDelivery from "./GroupDelivery";
import {array} from "prop-types";
import {DELIVERY_TYPE_GROUP} from "../../constains/types";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";

library.add(fas, faSuitcase, faMapMarkedAlt, faSort, faFileSignature, faArrowLeft, faInfoCircle, faMap, faPhoneAlt, faLocationArrow, faTruck,
    faCommentDots, faCamera, faChevronRight, faInfo, faFile, faMapMarked, faUser, faCheck, faTimes);

const Deliveries = () => {

    /** Global localstorage variables / variables declaration / constant declaration **/
    const sessionService = useSessionService();
    const {deliveriesService} = useDepServices();
    const dispatch = useDispatch();
    const deliveryListUpdatedTime = useSelector(selectDeliveryListUpdateTime)
    const isConfirmationNeeded = useSelector(selectConfirmationState)
    const [completedJobCnt, ongoingJobCnt] = deliveriesService.getLocalJobCounts()
    const deliveryType = useSelector(selectDeliveryType);
    const isVehicleChecked = useSelector(selectVehicleCheckedState);

    let favouriteQueue = sessionService.getDeliveredQueue();

    const [dbArray, setDbArray] = useState("");
    const [groupArray, setGroupArray] = useState("");
    const [unconfirmedArray, setUnconfirmedArray] = useState("");

    const {t,i18n} = useTranslation();



    const match = useRouteMatch({
        path: "/deliveries/:guid",
        exact: true,
        strict: true,
        sensitive: true
    });

    const jobguid = (match && match.params.guid) ? match.params.guid : '';
    //
    // const geolocation = useGeolocation({timeout: 10000});
    // const dbDeliveriesExist = Dexie.exists("DeliveriesResponse");
    // //const {messageDispatch} = useMessageContext();
    const [jobConfirmation, setJobConfirmation] = useState(false);

    let moment = require('moment');

    let history = useHistory();


    const [isSubmitting,setIsSubmitting] = useState(false);
    const [clearToSubmit, setClearToSubmit] = useState(false);
    const [toggleGroup,setToggleGroup] = UseLocalStorage("group_toggle",false);
    let entryDetails = [];
    // /** ##### Functions to set Job status after downloaded from the server #### **/
    //
    //
    // /** ##### Functions for return Job to the server #### **/
    //

    //
    // /** ##### Use Effect for Deliveries, one time execution #### **/
    //
    useEffect(() => {

        const fetchData = async () => {

            const dbArray = await deliveriesService.getDeliveries(),
                groupArray = await deliveriesService.getDeliveryGroups(),
                unconfirmedArray = await deliveriesService.getUnconfirmedJobs();

            setDbArray( dbArray);
            setGroupArray(groupArray);
            setUnconfirmedArray(unconfirmedArray);
            setIsSubmitting(false);
            if (unconfirmedArray.length > 0 && isConfirmationNeeded) {
                dispatch(push('/confirm'));
            }
        }

        setIsSubmitting(true);
        fetchData();

    }, [deliveryListUpdatedTime]);

    useEffect(() => {

        if(favouriteQueue === false){
            sessionService.isLogin() && notify('error', "vehicle_check.no_fav_queue" );
            dispatch(push('/jobs'));
        } else if(! sessionService.isVehicleCheckDone()) {
            dispatch(push('/vehicle'));
        }

    }, [isVehicleChecked]);
    /** ##### Path Matching from React Router #### **/

    

    //
    // /** ##### Function to switch between single job listing and Group listing #### **/
    //

    /** ##### functions for active window states #### **/

    const openGroupWindowHandler = (e) => {
        let targetID = e.currentTarget.getAttribute("data-id");
        let targetDiv = document.querySelector("#" + targetID);
        setToggleGroup(!toggleGroup);


        const toggleFormState = ()=>{
            targetDiv.classList.add('active');
            //setTimerIsActive(false);
        }
        const toggleFormStateOff = ()=>{
            targetDiv.classList.remove('active');
            //setTimerIsActive(true);
        }
        return (!targetDiv.classList.contains("active")  ? toggleFormState() : toggleFormStateOff());
    }

    /** ##### New Promise with Timeout #### **/

    const renderDeliveryDetails = () => {
        const renderDetailsLoading = () =>{
            if(isSubmitting || dbArray === ""){
                return(<div className="data-loading">
                    <h3>Loading... </h3>
                   <LoadingCircle isSubmitting={isSubmitting} />
                </div>);
            }else{
                const selectedObj = (deliveryType === DELIVERY_TYPE_GROUP ? groupArray : dbArray).find(element => element.guid === jobguid);
                selectedObj.job_extras.sort((e1, e2) => e1.field_position > e2.field_position ? 1 : -1);
                return(
                    <DeliveryFormContextProvider jobguids={(deliveryType === DELIVERY_TYPE_GROUP ? [] : [selectedObj.guid])} status={selectedObj.status} jobextras={selectedObj.job_extras} lineitems={selectedObj.line_items} lineitemactions={selectedObj.line_item_actions}>
                        {(deliveryType === DELIVERY_TYPE_GROUP) ? <GroupDelivery group={selectedObj}></GroupDelivery> : <SingleJobDelivery job={selectedObj}/>}
                    </DeliveryFormContextProvider>
                );
            }
        }

        if (match) {
            return (
                <Container fluid>
                    <div className="row data-list deliveries-content">
                        <div className="data-list-header" style={{padding: `env(safe-area-inset-top) 0.25rem 0.25rem 0.25rem`}}>
                            <h3 className="text-white m-0 p-2">{t(`ui:${deliveryType === DELIVERY_TYPE_GROUP ? 'group_delivery':'delivery_details'}.header`)}</h3>
                        </div>
                        <div className="data-list-content delivery-list-detail">
                            {renderDetailsLoading()}
                        </div>
                    </div>
                </Container>
            );
        }
    };




    const deliveriesHandler = (e)=>{
        //handleSubmit(e);
        e.preventDefault();
    }

    if(sessionService.isLogin() && favouriteQueue !== false && sessionService.isVehicleCheckDone()) {
        if (match && match.isExact === true) {
            return (renderDeliveryDetails());
        } else {
            return <DeliveriesListing dbArray={deliveryType === DELIVERY_TYPE_GROUP ? groupArray : dbArray} noOfJobs ={ongoingJobCnt + completedJobCnt} unconfirmedArray={unconfirmedArray}/>;
        }
    } else{
        return (<></>);
    }

};

export default Deliveries;
